// Here you can add other styles
@import url("https://fonts.googleapis.com/css?family=Montserrat:500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:700&display=swap");

@font-face {
  font-family: "Montserrat", sans-serif;
  src: url("https://fonts.googleapis.com/css?family=Montserrat:500&display=swap");
}

@font-face {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  src: url("https://fonts.googleapis.com/css?family=Montserrat:700&display=swap");
}

body {
  color: #3c4245;
  font-family: "Montserrat", sans-serif;
}
